import ParamsBuilder from '../../../helpers/paramsBuilder';
import { fields } from '../casino/utils';

export function getListParams(filter, sorting, pagination = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
	}

	builder.addField(rules.isID, 'userID', fields.userID);
	builder.addField(rules.isID, 'gameID', fields.gameID);
	builder.addField(rules.isID, 'transactionID', fields.transactionID);
	builder.addField(rules.isID, 'gameCurrencyID', fields.gameCurrencyID);
	builder.addField(rules.isID, 'gameProviderID', fields.gameProviderID);
	builder.addField(rules.isID, 'gameStatusID', fields.gameStatusID);

	builder.addField(rules.isString, 'userRegistrationIP', fields.userRegistrationIP);
	builder.addField(rules.isString, 'gameName', fields.gameName);
	builder.addField(rules.isString, 'gameIP', fields.gameIP);
	builder.addField(rules.isString, 'gameIPCountry', fields.gameIPCountry);

	builder.addField(rules.isArrayID, 'userStatusIDs', fields.userStatusID);
	builder.addField(rules.isArrayID, 'betTypeIDs', fields.betType);

	builder.addRangeField(rules.isDateTimeRange, filter.gamePlacementTime, [fields.gamePlacementTimeFrom, fields.gamePlacementTimeTo]);
	builder.addRangeField(rules.isNumberRange, filter.gameBetNumber, [fields.gameBetNumberFrom, fields.gameBetNumberTo]);
	builder.addField(rules.isString, 'betType', fields.betType);


	builder.addField(rules.isID, 'userID', fields.userID);
	builder.addField(rules.isID, 'currencyID', fields.currencyID);

	const params = builder.biuldParams(filter);

	return params;
}
