import { cloneDeep, isArray } from 'lodash';
import EntityAdapter from '../../../helpers/entityAdapter';
export const fields = {
	id    : 'id',
	name  : 'name',
	status: 'status',
};

export const statusCode = {
	active : 1,
	deleted: 2,
};

const adapterBaseData = createAdapterBaseData();

// Adapt ------------------------------------------------------------------------------------------

export function adaptIntegratorsList(rawData = [], withProvider) {

	function adaptArrayOfProviders(arr) {
		if (arr && isArray(arr)) {
			return arr.map(item => ({ id: item.provider_id, name: item.name }));
		}
		return [];
	}

	if ( withProvider ) {
		return rawData.map(
			rawDataItem => ({
				name     : rawDataItem.name,
				id       : rawDataItem.id,
				status   : rawDataItem.status,
				providers: adaptArrayOfProviders(rawDataItem.providers),
			})
		);
	}

	return rawData.map(
		rawDataItem => ({
			name  : rawDataItem.name,
			status: rawDataItem.status,
			id    : rawDataItem.id,
		})
	);

}

export function adaptPushIntegrator(oldEntities, data, update, loading) {

	if (update) {
		const oldEntitiesClone      = cloneDeep(oldEntities);
		const findIndex             = oldEntitiesClone.findIndex(item => item.id === data.id);

		if (!data.name) {
			oldEntitiesClone[findIndex] = { ...oldEntitiesClone[findIndex], loading };
		} else {
			oldEntitiesClone[findIndex] = { ...data, loading };
		}

		return { list: oldEntitiesClone };
	}

	const oldEntitiesClone     = cloneDeep(oldEntities);
	const newCreatedIntegrator = { ...data };
	oldEntitiesClone.unshift(newCreatedIntegrator);

	return { new: newCreatedIntegrator, list: oldEntitiesClone };
}

export function adaptBaseData(rawData) {
	adapterBaseData.clearExcludes();
	return adapterBaseData.adapt(rawData);
}

// Adapters ---------------------------------------------------------------------------------------
export function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'status', fields.status);

	return adapter;
}

export function createAdapterBaseData() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'status', fields.status);

	return adapter;
}

export function adaptEmptyIntegrators(oldEntities, bonusData) {
	const { count: { providers: providerCount }, integratorIDs, providerIDs } = bonusData;
	const oldEntitiesClone = cloneDeep(oldEntities);

	return oldEntitiesClone.filter(integrator => {
		const integratorChecked = integratorIDs.includes(integrator.id);
		if (integrator.status && +integrator.status === statusCode.deleted) {
			if (integrator?.providers) {
				const notCheckedProviders = integrator?.providers.filter(item => !providerIDs.includes(item.id));
				const checkedProviders = integrator?.providers.filter(item => providerIDs.includes(item.id));
				const integratorCount = notCheckedProviders.reduce((sum, item) => {
					if (providerCount[item.id]) {  // It cannot be 0, so checking 0 value is redundant.
						sum += providerCount[item.id];
					} else {
						providerCount[item.id] = 0;
					}
					return sum;
				}, 0);

				if (integratorChecked || checkedProviders.length > 0 || integratorCount > 0) {
					return true;
				}

				return Object.values(integrator.providers).some(({ checked: providerChecked }) => providerChecked);
			}
		}

		return true;
	});
}
