import { NavLink } from 'react-router-dom';

const PersonalLink = ({ userID, children }) => {
	return (
		<NavLink to={`/dashboard/personal/info/${userID}`} >
			{children}
		</NavLink>
	);
};

export default PersonalLink;
