import toInteger from 'lodash/toInteger';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import EntityAdapter from '../../../helpers/entityAdapter';

const fields = {
	id               : 'id',
	code             : 'code',
	description      : 'description',
	defaultCurrencyID: 'default_currency_id',
};

const websiteFields = {
	id         : 'id',
	code       : 'code',
	description: 'description',
	// websiteID   : 'website_id',
	// currencyID  : 'id',
	// orderID     : 'order_id',
};

const historyFields = {
	id          : 'id',
	date        : 'date',
	before      : 'object_before',
	after       : 'object_after',
	sourceID    : 'source_id',
	sourceTypeID: 'source_type',
	targetID    : 'to',
	rate        : 'rate',
};

const listAdapter        = createCurrencyListAdapter();
const websiteListAdapter = createWebsiteCurrencyListAdapter();
const historyAdapter     = createHistoryAdapter();
const rateAdapter        = createRateAdapter();

// Adapt ------------------------------------------------------------------------------------------
export function adaptCurrencyList(rawData = []) {

	listAdapter.clearExcludes();

	const adaptedData = listAdapter.adaptList(rawData);
	adaptedData.forEach(item => {
		item.name = `${item.description} (${item.code})`;
	});

	return adaptedData;
}

export function adaptWebsiteCurrencyList(rawData = []) {

	websiteListAdapter.clearExcludes();

	const adaptedData = websiteListAdapter.adaptList(rawData);
	adaptedData.forEach(item => {
		item.name = `${item.description} (${item.code})`;
	});

	return orderBy(adaptedData, ['orderID'], ['asc']);
}

export function adaptPayments(rawPaymentsList = [], rawPaywaysList = []) {
	if (!isArray(rawPaymentsList) || isEmpty(rawPaymentsList)) {
		return [];
	}

	const paywayItem     = rawPaywaysList[0] || {};
	const paywayPayments = paywayItem.payments || [];

	const result = [];
	rawPaymentsList.forEach( paymentItem => {
		const paymentID = toInteger(paymentItem.id);

		const existItem = paywayPayments.find( item => toInteger(item.id) === paymentID );

		const resultItem = {
			paymentID,
			paymentName: paymentItem.name,
			usage      : Boolean(existItem),
		};

		result.push(resultItem);
	});

	return result;
}

export function adaptRateList(rawRateList, currencyID, currencyList) {
	if (!isArray(rawRateList)) {
		rawRateList = [];
	}

	const rateList = [];

	currencyList.forEach(currencyItem => {
		const itemID = toInteger(currencyItem.id);
		if (itemID === currencyID) return;

		const dataItem = {
			to  : itemID,
			rate: 0,
		};

		const rateItem = rawRateList.find(item => {
			return toInteger(item.to) === itemID;
		});

		if (rateItem) dataItem.rate = rateItem.rate;

		rateList.push(dataItem);
	});

	return rateList;
}

export function adaptHistoryList(rawData = [], currencyEntities = {}) {
	if (!isArray(rawData) || isEmpty(rawData)) {
		return [];
	}
	historyAdapter.clearExcludes();
	rateAdapter.clearExcludes();

	const adaptedList = [];
	rawData.forEach(rawItem => {
		const adaptedItem = historyAdapter.adapt(rawItem);
		const before      = rateAdapter.adapt(adaptedItem.before);
		const after       = rateAdapter.adapt(adaptedItem.after);
		adaptedItem.targetID = after.targetID ? after.targetID : before.targetID;
		adaptedItem.rate     = after.rate ? after.rate : before.rate;
		// eslint-disable-next-line prefer-destructuring
		adaptedItem.day = adaptedItem.date.split(' ')[0];
		adaptedList.push(adaptedItem);
	});
	const groupedList = groupBy(adaptedList, 'date');
	const dates       = keys(groupedList);
	return dates.map(date => {
		const rates = groupedList[date];
		const record = {
			date,
		};
		rates.forEach(rateItem => {
			const { targetID, rate, sourceID, sourceTypeID } = rateItem;
			const currency = currencyEntities[targetID] || { code: 'NAN' };
			const fieldName = currency.code;
			record[fieldName] = rate;
			record.sourceID = sourceID;
			record.sourceTypeID = sourceTypeID;
		});

		return record;
	});
}

// Prepare ----------------------------------------------------------------------------------------
export function preparePaymentsList(rawPaymentsList, currencyID) {

	const IDs = rawPaymentsList.filter( item => item.usage ).map( item => toInteger(item.paymentID) );

	return {
		currency_id: currencyID,
		payment_id : IDs,
	};
}

// Adapters ---------------------------------------------------------------------------------------
function createCurrencyListAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'description', fields.description);
	adapter.addField(rules.string, 'code', fields.code);
	adapter.addField(rules.string, 'defaultCurrencyID', fields.defaultCurrencyID);

	return adapter;
}

function createWebsiteCurrencyListAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'id', websiteFields.id);
	// adapter.addField(rules.id, 'websiteID', websiteFields.websiteID);
	// adapter.addField(rules.id, 'orderID', websiteFields.orderID);

	adapter.addField(rules.string, 'description', websiteFields.description);
	adapter.addField(rules.string, 'code', websiteFields.code);

	return adapter;
}

function createHistoryAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', historyFields.id);
	adapter.addField(rules.id, 'sourceTypeID', historyFields.sourceTypeID);
	adapter.addField(rules.id, 'sourceID', historyFields.sourceID);
	adapter.addField(rules.fullDate, 'date', historyFields.date);
	adapter.addField(rules.object, 'before', historyFields.before);
	adapter.addField(rules.object, 'after', historyFields.after);

	return adapter;
}

function createRateAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'targetID', historyFields.targetID);
	adapter.addField(rules.number, 'rate', historyFields.rate);

	return adapter;
}

// Service ----------------------------------------------------------------------------------------
export function convertToEntities(currencyList = []) {
	const entities = {};
	if (!isArray(currencyList)) {
		return entities;
	}

	currencyList.forEach(item => {
		const itemID     = toInteger(item.id);
		entities[itemID] = convertToEntity(item);
	});

	return entities;
}

export function convertToEntity(currencyItem) {

	return {
		id         : currencyItem.id,
		code       : currencyItem.code,
		currencyID : currencyItem.currency_id,
		description: currencyItem.description,
		name       : `${currencyItem.description} (${currencyItem.code})`,
		hasRates   : false,
		rates      : [],
	};
}
