import toInteger from 'lodash/toInteger';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { isID } from '../../../../helpers/utils';
import { createEntities } from '../../../utility';

export const fields = {
	id                : 'id',
	name              : 'name',
	statusID          : 'status_id',
	domain            : 'domain',
	langID            : 'lang_id',
	currencyID        : 'currency_id',
	customerAge       : 'customer_age',
	dateFormat        : 'date_format',
	googleAnalytics   : 'analytics_id',
	suspendAll        : 'suspend_all',
	suspendPrematch   : 'suspend_prematch',
	suspendInplay     : 'suspend_inplay',
	website_mailing   : 'website_mailing',
	website_cdn       : 'website_cdn',
	website_crms      : 'website_crms',
	website_affiliates: 'website_affiliates',
	subCategoryType   : 'sub_category_type',
	gameListLoad      : 'game_load_type',
	websiteMailing    : 'website_mailing',
	websiteCdn        : 'website_cdn',
	websiteCrms       : 'website_crms',
	websiteAffiliates : 'website_affiliates',
	description       : 'description',
	phoneVerification : 'phone_verification',
	emailVerification : 'email_verification',
	logoURL           : 'logo',

	suspendPrematchInPartner: 'partner_suspend_prematch',
	suspendInPlayInPartner  : 'partner_suspend_live',

	suspendPrematchByPlatform: 'suspend_prematch_by_platform',
	suspendInPlayByPlatform  : 'suspend_in_play_by_platform',
	suspendAllByPlatform     : 'suspend_all_betting_by_platform',
	changedStatusByPlatform  : 'changed_status_by_platform',

	// payments
	payments        : 'payments',
	paymentID       : 'payment_id',
	websitePaymentID: 'website_payment_id',
	merchantID      : 'merchant_id',
	merchantPassword: 'merchant_password',
	websiteID       : 'website_id',
	apiID           : 'api_id',
	apiSecretKey    : 'api_secret_key',
	autoWithdraw    : 'auto_withdraw',
	orderID         : 'order_id',
	maxDeposit      : 'max_deposit',
	maxWithdraw     : 'max_withdraw',
	minDeposit      : 'min_deposit',
	minWithdraw     : 'min_withdraw',
	favAmounts      : 'favorite_amounts',
	depositName     : 'deposit_name',
	withdrawalName  : 'withdrawal_name',
	// casino
	casinoID        : 'casino_id',
	apiProject      : 'api_project',
	apiKey          : 'api_key',
	apiVersion      : 'api_version',
	apiHost         : 'api_host',
	test            : 'test',
	apiUrl          : 'api_url',
	apiSecondUrl    : 'api_second_url',
	sandboxUrl      : 'sandbox_url',

	cloudflare         : 'cloud_flare_key',
	code               : 'code',
	isDefault          : 'is_default',
	isoCode            : 'iso_code',
	countryID          : 'country_id',
	enableGDPR         : 'require_cookie',
	enableNationalID   : 'national_id',
	enableOCR          : 'enable_ocr',
	gameGrid           : 'enable_game_grid',
	enableAllGames     : 'enable_all_games',
	responsibleStatus  : 'rg_status',
	responsibleDBStatus: 'rg_status',
	responsiblePeriod  : 'rg_period',

	seoKeywords   : 'seo_keywords',
	seoTitle      : 'seo_title',
	seoDescription: 'seo_description',
	OGDescription : 'og_description',
	OGImage       : 'og_image',
	OGTitle       : 'og_title',
};

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptWebsitesList(rawData = []) {
	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);

	return createEntities(adaptedData);
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareWebsite(rawData = {}) {
	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');

	const preparedData = listAdapter.prepare(rawData);
	if (isID(rawData.id)) {
		preparedData.id = toInteger(rawData.id);
	}

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'subCategoryType', fields.subCategoryType);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'currencyID', fields.currencyID);
	adapter.addField(rules.numberOrNull, 'gameListLoad', fields.gameListLoad);
	adapter.addField(rules.number, 'customerAge', fields.customerAge);
	adapter.addField(rules.object, 'website_mailing', fields.websiteMailing);
	adapter.addField(rules.object, 'website_cdn', fields.websiteCdn);
	adapter.addField(rules.arrayObject, 'website_crms', fields.websiteCrms);
	adapter.addField(rules.arrayObject, 'website_affiliates', fields.websiteAffiliates);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'domain', fields.domain);
	adapter.addField(rules.string, 'dateFormat', fields.dateFormat);
	adapter.addField(rules.string, 'googleAnalytics', fields.googleAnalytics);
	adapter.addField(rules.string, 'logoURL', fields.logoURL);

	adapter.addField(rules.number, 'phoneVerification', fields.phoneVerification);
	adapter.addField(rules.number, 'emailVerification', fields.emailVerification);

	adapter.addField(rules.string, 'cloudflare', fields.cloudflare);

	adapter.addField(rules.number, 'phoneVerification', fields.phoneVerification);
	adapter.addField(rules.number, 'emailVerification', fields.emailVerification);

	adapter.addField(rules.bool, 'enableGDPR', fields.enableGDPR);
	adapter.addField(rules.bool, 'enableNationalID', fields.enableNationalID);
	adapter.addField(rules.bool, 'enableOCR', fields.enableOCR);
	adapter.addField(rules.bool, 'gameGrid', fields.gameGrid);
	adapter.addField(rules.bool, 'enableAllGames', fields.enableAllGames);
	adapter.addField(rules.bool, 'suspendAll',      fields.suspendAll);
	adapter.addField(rules.bool, 'suspendPrematch', fields.suspendPrematch);
	adapter.addField(rules.bool, 'suspendInplay',   fields.suspendInplay);

	adapter.addField(rules.bool, 'suspendPrematchInPartner', fields.suspendPrematchInPartner);
	adapter.addField(rules.bool, 'suspendInPlayInPartner', fields.suspendInPlayInPartner);

	adapter.addField(rules.bool, 'suspendPrematchByPlatform', fields.suspendPrematchByPlatform);
	adapter.addField(rules.bool, 'suspendInPlayByPlatform', fields.suspendInPlayByPlatform);
	adapter.addField(rules.bool, 'suspendAllByPlatform', fields.suspendAllByPlatform);
	adapter.addField(rules.bool, 'changedStatusByPlatform', fields.changedStatusByPlatform);
	adapter.addField(rules.numberOrNull, 'responsiblePeriod', fields.responsiblePeriod);
	adapter.addField(rules.bool, 'responsibleStatus', fields.responsibleStatus);
	adapter.addField(rules.bool, 'responsibleDBStatus', fields.responsibleDBStatus);

	return adapter;
}

export function createPaymentsListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'paymentID', fields.paymentID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.id, 'orderID', fields.orderID);

	adapter.addField(rules.string, 'merchantID', fields.merchantID);
	adapter.addField(rules.string, 'merchantPassword', fields.merchantPassword);
	adapter.addField(rules.string, 'apiID', fields.apiID);
	adapter.addField(rules.string, 'apiSecretKey', fields.apiSecretKey);

	adapter.addField(rules.bool, 'autoWithdraw', fields.autoWithdraw);

	return adapter;
}

export function createPaymentLimitsAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'websitePaymentID', fields.websitePaymentID);
	adapter.addField(rules.id, 'currencyID', fields.currencyID);

	adapter.addField(rules.nullOrNumber, 'maxDeposit', fields.maxDeposit);
	adapter.addField(rules.nullOrNumber, 'maxWithdraw', fields.maxWithdraw);
	adapter.addField(rules.nullOrNumber, 'minDeposit', fields.minDeposit);
	adapter.addField(rules.nullOrNumber, 'minWithdraw', fields.minWithdraw);
	adapter.addField(rules.arrayNumber, 'favAmounts', fields.favAmounts);

	return adapter;
}

export function createPaymentDescriptionsAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'currencyID', fields.currencyID);
	adapter.addField(rules.string, 'description', fields.description);

	return adapter;
}

export function createCasinoListAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'casinoID', fields.casinoID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);

	adapter.addField(rules.id, 'apiProject', fields.apiProject);
	adapter.addField(rules.id, 'apiVersion', fields.apiVersion);
	adapter.addField(rules.id, 'apiHost', fields.apiHost);

	adapter.addField(rules.string, 'apiID', fields.apiID);
	adapter.addField(rules.string, 'apiKey', fields.apiKey);
	adapter.addField(rules.bool, 'test', fields.test);
	adapter.addField(rules.string, 'apiUrl', fields.apiUrl);
	adapter.addField(rules.string, 'apiSecondUrl', fields.apiSecondUrl);
	adapter.addField(rules.string, 'sandboxUrl', fields.sandboxUrl);

	return adapter;
}

export function createSeoAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.string, 'SEOTitle', fields.seoTitle);
	adapter.addField(rules.string, 'SEODescription', fields.seoDescription);
	adapter.addField(rules.string, 'SEOKeywords', fields.seoKeywords);

	adapter.addField(rules.string, 'OGTitle', fields.OGTitle);
	adapter.addField(rules.string, 'OGDescription', fields.OGDescription);
	adapter.addField(rules.string, 'OGImage', fields.OGImage);
	adapter.addField(rules.string, 'alias', fields.alias);
	adapter.addField(rules.string, 'langID', fields.langID);

	return adapter;
}

export function createCurrencyListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.id, 'currencyID', fields.currencyID);
	adapter.addField(rules.id, 'orderID', fields.orderID);
	adapter.addField(rules.string, 'description', fields.description);

	return adapter;
}

export function createLanguageListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'languageID', fields.id);
	adapter.addField(rules.string, 'code', fields.code);
	adapter.addField(rules.bool, 'isDefault', fields.isDefault);
	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}

export function createAttachedLanguageListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'languageID', fields.langID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.id, 'orderID', fields.orderID);

	return adapter;
}

export function createCountryListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'countryID', fields.id);
	adapter.addField(rules.string, 'code', fields.isoCode);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);

	return adapter;
}

export function createCountryListPreparer() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'countryID', fields.countryID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);

	return adapter;
}

export function createAttachedCountryListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'countryID', fields.countryID);
	adapter.addField(rules.string, 'code', fields.isoCode);
	adapter.addField(rules.string, 'name', fields.name);

	return adapter;
}
