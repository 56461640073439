import { v4 as uuid } from 'uuid';
import isArray from 'lodash/isArray';

import ParamsBuilder from '../../../helpers/paramsBuilder';
import EntityAdapter from '../../../helpers/entityAdapter';

export const fields = {
	userID       : 'user_id',            // number;
	userIP       : 'ip',                 // string;
	firebaseToken: 'registration_token', // string;
	userCountry  : 'country',            // string;
	created      : 'created',            // date;
	languageID   : 'language_id',           // string;
	languageIDs  : 'language_ids',           // string[];

	title   : 'title',              // string;
	message : 'body',               // string;
	imageURL: 'img',                // string;
	action  : 'action',             // string;

	sortBy     : 'sort_by',             // string
	sortOrder  : 'sort_order',          // string
	//filter
	createdFrom: 'created_from',       // Date;
	createdTo  : 'created_to',         // Date;
	channelID  : 'channel_id',         // number;
	balanceFrom: 'balance_from',
	balanceTo  : 'balance_to',
};

const listAdapter    = createListAdapter();
const messageAdapter = createMessageAdapter();

export function getListParams(filter, sorting, pagination = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	if (pagination) {
		builder.addValue('page', pagination.currentPage);
		builder.addValue('limit', pagination.itemsPerPage);
	}

	builder.addField(rules.isID, 'userID', fields.userID);
	builder.addField(rules.isID, 'channelID', fields.channelID);

	builder.addField(rules.isString, 'userIP', fields.userIP);
	builder.addField(rules.isString, 'userCountry', fields.userCountry);
	builder.addField(rules.isString, 'language', filter?.language?.length > 1 ? fields.languageIDs : fields.languageID);


	builder.addRangeField(rules.isDateTimeRange, filter.created, [fields.createdFrom, fields.createdTo]);
	builder.addRangeField(rules.isNumberRange, filter.balance, [fields.balanceFrom, fields.balanceTo]);

	builder.addField(rules.isString, 'sortBy',  fields.sortBy);
	builder.addField(rules.isString, 'sortOrder',  fields.sortOrder);

	const params = builder.biuldParams({ ...filter, ...sorting });

	return params;
}

// Adapt ------------------------------------------------------------------------------------------
export function adaptList(rawData = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adaptList(rawData);
	adaptedData.forEach(item => {
		item.id = uuid();
	});

	// const tempData = {
	//   items: adaptedData,
	// };

	// const item           = new schema.Entity('items', {}, { idAttribute: 'id' });
	// const itemsSchema    = { items: [item] };
	// const normalizedData = normalize(tempData, itemsSchema);
	// const entities       = normalizedData.entities.items || {};

	// return entities;
	return adaptedData;
}

// Prepare ----------------------------------------------------------------------------------------
export function prepareNotification(rawData = {}) {

	messageAdapter.clearExcludes();
	return messageAdapter.prepare(rawData);
}

// Adapters ---------------------------------------------------------------------------------------
export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'channelID', fields.channelID);

	adapter.addField(rules.string, 'userIP', fields.userIP);
	adapter.addField(rules.string, 'firebaseToken', fields.firebaseToken);
	adapter.addField(rules.string, 'userCountry', fields.userCountry);

	adapter.addField(rules.fullDate, 'created', fields.created);

	return adapter;
}

export function createMessageAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'title', fields.title);
	adapter.addField(rules.string, 'message', fields.message);
	adapter.addField(rules.string, 'imageURL', fields.imageURL);
	adapter.addField(rules.string, 'action', fields.action);
	adapter.addField(rules.arrayString, 'types', fields.types);

	return adapter;
}
export const checkSortNotifications = (sorting) => {
	const checkColumn = {
		created      : 'created',
		firebaseToken: 'firebase_token',
		userID       : 'user_id',
		userIP       : 'user_ip',
		userCountry  : 'user_country',
	};

	if ( checkColumn[sorting.sortBy] ) {
		return {
			sortBy   : checkColumn[sorting.sortBy],
			sortOrder: sorting.sortOrder,
		};
	}
	return { sortOrder: null, sortBy: null };
};
