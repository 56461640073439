
import toInteger from 'lodash/toInteger';
import { isID } from '../../../../helpers/utils';
import { createListAdapter } from '../groups/utils';
import { BANNER_DISPLAY_STATUS } from '../../../../helpers/commonConstants';

const listAdapter = createListAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptBannerGroup(rawData = {}) {
	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adapt(rawData);
	adaptedData.statusID = rawData.status_id === BANNER_DISPLAY_STATUS.active;

	return adaptedData;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareBannerGroup(baseData) {
	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');

	const preparedData = listAdapter.prepare(baseData);
	if (isID(baseData.id)) {
		preparedData.id = toInteger(baseData.id);
	}

	preparedData.status_id = preparedData.status_id ? BANNER_DISPLAY_STATUS.active : BANNER_DISPLAY_STATUS.inactive;
	return preparedData;
}
