import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import actions from './actions';

import { CONTENT_MENU_TYPES } from '../../../../helpers/commonConstants';
import { contentAPI } from '../../../../helpers/api/content';
import notifications from '../../../../helpers/notifications';
import { infoPagesAPI } from '../../../../helpers/api/infoPages';

import {
	adaptSettingsData,
	prepareSettingsData,
	adaptColumn,
	prepareColumn,
	adaptItem,
	prepareItem,
	prepareColumnsList,
	prepareItemsList,
	adaptRevisionsList,
	createRevisionAdapter,
} from './utils';
import { logger } from '../../../../helpers/logger';
import { getItems } from '../../../../containers/Cms/Menu/components/Board/utils';
import { createEntities } from '../../../utility';

const prefix = 'menu.articles';

const messages = {
	errorSettingsLoad   : `${prefix}.loading.settings.failed`,
	errorColumnLoad     : `${prefix}.loading.column.data.failed`,
	errorItemLoad       : `${prefix}.loading.item.data.failed`,
	errorColumnSave     : `${prefix}.saving.column.failed`,
	errorItemSave       : `${prefix}.saving.item.failed`,
	errorItemsOrder     : `${prefix}.updating.items.order.failed`,
	errorColumnsOrder   : `${prefix}.updating.columns.order.failed`,
	errorRevisionsLoad  : `${prefix}.loading.revisions.failed`,
	errorRevisionSave   : `${prefix}.saving.revisions.failed`,
	errorRevisionApply  : `${prefix}.applying.revision.failed`,
	successColumnSave   : `${prefix}.column.saved.success`,
	successColumnsOrder : `${prefix}.columns.order.updated.success`,
	successItemSave     : `${prefix}.item.saved.success`,
	successItemsOrder   : `${prefix}.items.order.updated.success`,
	successRevisionSave : `${prefix}.revision.updated.success`,
	successRevisionApply: `${prefix}.revision.applied.success`,
};

const menuTypeID = CONTENT_MENU_TYPES.articlesMenu;

function getStoreData({ CMS, App }) {

	return {
		columnsIDs       : CMS.Menu.Articles.get('columnsIDs'),
		columnsEntities  : CMS.Menu.Articles.get('columnsEntities'),
		itemsIDs         : CMS.Menu.Articles.get('itemsIDs'),
		itemsEntities    : CMS.Menu.Articles.get('itemsEntities'),
		modalColumnUI    : CMS.Menu.Articles.get('modalColumnUI'),
		modalItemUI      : CMS.Menu.Articles.get('modalItemUI'),
		revisionsEntities: CMS.Menu.Articles.get('revisionsEntities'),
		websiteID        : App.get('websiteID'),
	};
}

function* settingsReload() {

	yield takeEvery(actions.CONTENT_ARTICLES_SETTINGS_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		let columnsIDs      = [];
		let itemsIDs        = [];
		let columnsEntities = {};
		let itemsEntities   = {};
		try {
			const res = yield call(contentAPI.contentMenuLoad, menuTypeID);
			if (res && res.status === 200) {
				const result    = adaptSettingsData(res.data.data);
				columnsIDs      = result.columnsIDs;
				itemsIDs        = result.itemsIDs;
				columnsEntities = result.columnsEntities;
				itemsEntities   = result.itemsEntities;
			}
		} catch (error) {
			notifications.showError(messages.errorSettingsLoad, error);
			logger.error(error);
		}

		yield put(
			actions.settingsRefresh(
				columnsIDs,
				columnsEntities,
				itemsIDs,
				itemsEntities
			)
		);
		yield put(
			actions.uiRefresh({
				loading      : false,
				isMenuChanged: false,
			})
		);
	});
}

function* columnSave() {

	yield takeEvery(actions.CONTENT_ARTICLES_COLUMN_SAVE, function* () {
		yield put(actions.uiColumnRefresh({ loading: true }));

		const storeData = yield select(getStoreData);

		const columnsIDs      = cloneDeep(storeData.columnsIDs);
		const columnsEntities = cloneDeep(storeData.columnsEntities);

		const { modalColumnUI, websiteID } = storeData;
		const { editMode, columnItem, closeModal, langID } = modalColumnUI;

		let { columnID } = modalColumnUI;
		let isError = false;

		const preparedData = prepareColumn(columnItem, langID);
		preparedData.website_id = websiteID;

		try {
			if (editMode) {
				const res = yield call(
					contentAPI.contentMenuColumnUpdate,
					columnID,
					preparedData
				);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successColumnSave);
				}
			} else {
				const res = yield call(contentAPI.contentMenuColumnAdd, preparedData);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successColumnSave);
					columnID      = toInteger(res.data.data.id);
					columnItem.id = columnID;
				}
			}
		} catch (error) {
			isError = true;
			notifications.showError(messages.errorColumnSave, error);
			logger.error(error);
		}

		if (!isError) {
			columnsEntities[columnID] = columnItem;
			if (!editMode) {
				columnsIDs.push(columnID);
			}
			yield put(actions.columnsListRefresh(columnsIDs, columnsEntities));
			yield put(
				actions.uiRefresh({
					isMenuChanged: true,
					revisionID   : null,
				})
			);
		}

		yield put(
			actions.uiColumnRefresh({
				loading: false,
				visible: !(closeModal && !isError),
			})
		);
	});
}

function* columnReload() {

	yield takeEvery(actions.CONTENT_ARTICLES_COLUMN_RELOAD, function* (action) {

		yield put(actions.uiColumnRefresh({ loading: true }));

		const storeData = yield select(getStoreData);

		const columnsIDs = cloneDeep(storeData.columnsIDs);
		const columnsEntities = cloneDeep(storeData.columnsEntities);
		const { langID } = storeData.modalColumnUI;

		const { columnID } = action.data;
		const params = { lang_id: langID };

		try {
			const res = yield call(
				contentAPI.contentMenuColumnInfo,
				columnID,
				params
			);
			if (res && res.status === 200) {
				const adaptedData = adaptColumn(res.data.data);
				columnsEntities[columnID] = adaptedData;

				yield put(actions.columnsListRefresh(columnsIDs, columnsEntities));
			}
		} catch (error) {
			notifications.showError(messages.errorColumnLoad, error);
			logger.error(error);
		}

		yield put(actions.uiColumnRefresh({ loading: false }));
	});
}

function* columnsOrdersUpdate() {
	yield takeEvery(actions.CONTENT_ARTICLES_COLUMNS_ORDER_UPDATE, function* (action) {
		const { columnsList } = action.data;
		yield put(actions.uiRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const columnsIDs = cloneDeep(storeData.columnsIDs);
		const columnsEntities = cloneDeep(storeData.columnsEntities);
		const preparedData = prepareColumnsList(columnsList);
		preparedData.website_id = storeData.websiteID;

		try {
			const res = yield call(
				contentAPI.contentMenuColumnsOrderUpdate,
				preparedData
			);
			if (res && res.status === 200) {
				res.data.data.forEach(item => {
					const columnID = item.id;
					columnsEntities[columnID].orderID = item.order_id;
				});

				yield put(actions.columnsListRefresh(columnsIDs, columnsEntities));
				yield put(
					actions.uiRefresh({
						loading      : false,
						isMenuChanged: true,
						revisionID   : null,
					})
				);

				notifications.showSuccess(messages.successColumnsOrder);
			}
		} catch (error) {
			notifications.showError(messages.errorColumnsOrder, error);
			logger.error(error);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* itemSave() {
	yield takeEvery(actions.CONTENT_ARTICLES_ITEM_SAVE, function* () {
		yield put(actions.uiItemRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const itemsIDs      = cloneDeep(storeData.itemsIDs);
		const itemsEntities = cloneDeep(storeData.itemsEntities);

		const { modalItemUI, websiteID } = storeData;
		const { editMode, closeModal, langID } = modalItemUI;
		let { item } = modalItemUI;
		let { itemID } = modalItemUI;
		let isError = false;

		const preparedData = prepareItem(item, langID);
		preparedData.website_id = websiteID;

		try {
			if (editMode) {
				const res = yield call(
					contentAPI.contentMenuItemUpdate,
					itemID.replace(/[a-zA-Z]/, ''),
					preparedData
				);
				if (res && res.status === 200) {
					const adaptedData = adaptItem(res.data.data);
					item = {
						...adaptedData,
						id: item.id,
					};
					notifications.showSuccess(messages.successItemSave);
				}
			} else {
				const res = yield call(contentAPI.contentMenuItemAdd, preparedData);
				if (res && res.status === 200) {
					notifications.showSuccess(messages.successItemSave);
					const { id } = res.data.data;
					itemID	= `G${id}`;
					item.id	= itemID;
				}
			}
		} catch (error) {
			isError = true;
			notifications.showError(messages.errorItemSave, error);
			logger.error(error);
		}

		if (!isError) {
			itemsEntities[itemID] = item;
			if (!editMode) {
				itemsIDs.push(itemID);
			}
			yield put(actions.itemsListRefresh(itemsIDs, itemsEntities));
			yield put(actions.uiRefresh({
				isMenuChanged: true,
				revisionID   : null,
			}));
		}

		yield put(actions.uiItemRefresh({
			loading: false,
			visible: !(closeModal && !isError),
		}));
	});
}

function* itemReload() {
	yield takeEvery(actions.CONTENT_ARTICLES_ITEM_RELOAD, function* (action) {
		yield put(actions.uiItemRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const itemsIDs = cloneDeep(storeData.itemsIDs);
		const itemsEntities = cloneDeep(storeData.itemsEntities);
		const { langID } = storeData.modalColumnUI;

		const { itemID } = action.data;
		const params = { lang_id: langID };

		try {
			const res = yield call(infoPagesAPI.infoPageInfo, itemID, params); // 
			if (res && res.status === 200) {
				const adaptedData = adaptItem(res.data.data);
				itemsEntities[itemID] = adaptedData;
				yield put(actions.itemsListRefresh(itemsIDs, itemsEntities));
			}
		} catch (error) {
			notifications.showError(messages.errorItemLoad, error);
			logger.error(error);
		}

		yield put(actions.uiItemRefresh({ loading: false }));
	});
}

function* itemsOrdersUpdate() {
	yield takeEvery(actions.CONTENT_ARTICLES_ITEMS_ORDER_UPDATE, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const { removedItem, movingSameList, movedColumnID, movedIndex } = action.data;

		const storeData     = yield select(getStoreData);
		const itemsIDs      = cloneDeep(storeData.itemsIDs);
		const itemsEntities = cloneDeep(storeData.itemsEntities);
		const itemsList		= getItems(movedColumnID, itemsEntities, true);

		if (movingSameList) {
			const oldItemIndex = itemsList.findIndex(item => item.id === removedItem.id);
			itemsList.splice(oldItemIndex, 1);
			itemsList.splice(movedIndex, 0, removedItem);
		} else {
			
			itemsList.splice(movedIndex, 0, removedItem);
		}

		const preparedData	= prepareItemsList(itemsList, movedColumnID);
		preparedData.website_id = storeData.websiteID;

		try {
			const res = yield call(
				contentAPI.contentMenuItemsOrderUpdate,
				preparedData
			);
			if (res && res.status === 200) {
				itemsEntities[removedItem.id].colID = movedColumnID;
				itemsList.forEach(storeItem => {
					preparedData.forEach(item => {
						if (item.id === toInteger(storeItem.id.replace(/[a-zA-Z]/, '' ))) {
							storeItem.orderID = item.order_id;
						}
					});
				});
				const newEntities = createEntities(itemsList);
				const updatedData = {
					...newEntities,
					...itemsEntities,
				};
				yield put(actions.itemsListRefresh(itemsIDs, updatedData));
				yield put(actions.uiRefresh({
					loading      : false,
					isMenuChanged: true,
					revisionID   : null,
				}));

				notifications.showSuccess(messages.successItemsOrder);
			}

		} catch (error) {
			notifications.showError(messages.errorItemsOrder, error);
			logger.error(error);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* revisionsListReload() {

	yield takeEvery(actions.CONTENT_ARTICLES_REVISIONS_LIST_RELOAD, function* () {

		let revisionsEntities = {};
		let activeRevisionID = null;
		try {
			const res = yield call(contentAPI.contentMenuRevisionsList, menuTypeID);
			if (res && res.status === 200) {
				const result      = adaptRevisionsList(res.data.data);
				revisionsEntities = result.revisionsEntities;
				activeRevisionID  = result.activeRevisionID;
			}

		} catch (error) {
			notifications.showError(messages.errorRevisionsLoad, error);
			logger.error(error);
		}

		yield put(actions.revisionsListRefresh(revisionsEntities));
		yield put(actions.uiRefresh({ revisionID: activeRevisionID }));
	});
}

function* revisionSave() {
	yield takeEvery(actions.CONTENT_ARTICLES_REVISION_SAVE, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { columnsEntities, itemsEntities, websiteID, revisionsEntities } = yield select(getStoreData);
		const preparedData = prepareSettingsData(columnsEntities, itemsEntities);
		preparedData.website_id = websiteID;

		try {
			const res = yield call(
				contentAPI.contentMenuRevisionAdd,
				menuTypeID,
				preparedData
			);
			if (res && res.status === 200) {
				const revisionID = toInteger(res.data.data.id);

				const revisionAdapter = createRevisionAdapter();
				revisionAdapter.clearExcludes();
				const revisionsEntity = revisionAdapter.adapt(res.data.data);
				const clonedRevisionsEntities = structuredClone(revisionsEntities);
				clonedRevisionsEntities[revisionID] = revisionsEntity;

				yield put(actions.revisionsListRefresh(clonedRevisionsEntities));
				yield put(actions.uiRefresh({
					loading      : false,
					isMenuChanged: false,
					revisionID,
				}));

				notifications.showSuccess(messages.successRevisionSave);
			}

		} catch (error) {
			notifications.showError(messages.errorRevisionSave, error);
			logger.error(error);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

function* revisionApply() {
	yield takeEvery(actions.CONTENT_ARTICLES_REVISION_APPLY, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const { revisionID } = action.data;

		try {
			const res = yield call(
				contentAPI.contentMenuRevisionApply,
				menuTypeID,
				revisionID
			);
			if (res && res.status === 200) {
				yield put(actions.revisionsListReload());
				yield put(actions.settingsReload());

				notifications.showSuccess(messages.successRevisionApply);
			}

		} catch (error) {
			notifications.showError(messages.errorRevisionApply, error);
			logger.error(error);
			yield put(actions.uiRefresh({ loading: false }));
		}
	});
}

export default function* contentFooterSaga() {
	yield all([
		fork(settingsReload),

		fork(columnSave),
		fork(columnReload),
		fork(columnsOrdersUpdate),

		fork(itemSave),
		fork(itemReload),
		fork(itemsOrdersUpdate),

		fork(revisionsListReload),
		fork(revisionSave),
		fork(revisionApply),
	]);
}
