const actions = {

	CASINO_CUSTOM_PROVIDERS_LIST_RELOAD     : 'CASINO_CUSTOM_PROVIDERS_LIST_RELOAD',
	CASINO_CUSTOM_PROVIDERS_LIST_REFRESH    : 'CASINO_CUSTOM_PROVIDERS_LIST_REFRESH',
	CASINO_CUSTOM_PROVIDERS_LIST_DELETE_ITEM: 'CASINO_CUSTOM_PROVIDERS_LIST_DELETE_ITEM',
	CASINO_CUSTOM_PROVIDERS_RELOAD_ITEM     : 'CASINO_CUSTOM_PROVIDERS_RELOAD_ITEM',

	CASINO_CUSTOM_PROVIDERS_LIST_UI_REFRESH: 'CASINO_CUSTOM_PROVIDERS_LIST_UI_REFRESH',

	listReload: () => ({
		type: actions.CASINO_CUSTOM_PROVIDERS_LIST_RELOAD,
	}),
	listRefresh: entities => ({
		type: actions.CASINO_CUSTOM_PROVIDERS_LIST_REFRESH,
		data: {
			entities,
		},
	}),
	reloadItem: data => ({
		type: actions.CASINO_CUSTOM_PROVIDERS_RELOAD_ITEM,
		data,
	}),
	deleteItem: customProviderID => ({
		type: actions.CASINO_CUSTOM_PROVIDERS_LIST_DELETE_ITEM,
		data: {
			customProviderID,
		},
	}),
	uiRefresh: data => ({
		type: actions.CASINO_CUSTOM_PROVIDERS_LIST_UI_REFRESH,
		data,
	}),
};

export default actions;
