import { toInteger } from 'lodash';
import { DISPLAY_STATUS } from '../../../../../helpers/commonConstants';

export const grid = 8;
export const borderRadius = 2;
export const scrollContainerHeight = 250;
export const fontSize = '18px';

export const generateTreeMap = (columnsEntities, columnsIDs, itemsEntities, showInvisible) => {

	return columnsIDs.reduce((acc, columnID) => {
		if (!showInvisible && columnsEntities[columnID].displayStatusID === DISPLAY_STATUS.invisible) {
			return acc;
		}
		return {
			...acc,
			[columnsEntities[columnID].id]: {
				columnID,
				orderID: columnsEntities[columnID].orderID,
				title  : columnsEntities[columnID].title,
				items  : getItems(columnID, itemsEntities, showInvisible),
			}, 
		};
		
	}, {});
};
  
  
export const getItems = (columnID, itemsEntities, showInvisible) => {
	return Object.values(itemsEntities).filter(item => {
		const filtered = toInteger(item.colID) === toInteger(columnID);
		if (showInvisible) {
			return filtered;
		} else {
			return filtered && item.displayStatusID === DISPLAY_STATUS.visible;
		}
	});
};
  
export const getOwnItemsIDs = (originItemsIDs, itemsEntities, columnID) => {

	const ownItemsIDs = originItemsIDs
		.filter(ID => itemsEntities[ID].colID === columnID)
		.sort((a, b) => itemsEntities[a].orderID > itemsEntities[b].orderID ? 1 : -1);

	return ownItemsIDs;
};

export const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
	if (isDraggingOver) {
		return '#FFEBE6';
	}
	if (isDraggingFrom) {
		return '#E6FCFF';
	}
	return '#EBECF0';
};

export function getStyle(provided, style) {
	if (!style) {
		return provided.draggableProps.style;
	}

	return {
		...provided.draggableProps.style,
		...style,
	};
}

export const getBackgroundColorItem = (isDragging, isGroupedOver) => {
	if (isDragging) {
		return '#FFFAE6';
	}

	if (isGroupedOver) {
		return '#EBECF0';
	}

	return '#FFFFFF';
};


export const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
  
	return {
		result,
		removed,
	};
};

  
export const reorderTreeMap = ({ treeMap, source, destination }) => {

	const current = [...treeMap[source.droppableId].items];
	const next = [...treeMap[destination.droppableId].items];
	const target = current[source.index];
  
	// moving to same list
	if (source.droppableId === destination.droppableId) {
		const { result: reordered, removed } = reorder(current, source.index, destination.index);
		const result = {
			...treeMap,
			[source.droppableId]: {
				columnID: treeMap[source.droppableId].columnID,
				orderID : treeMap[source.droppableId].orderID,
				title   : treeMap[source.droppableId].title,
				items   : reordered,
			},
		};
		return {
			treeMap       : result,
			movingSameList: true,
			removed,
		};
	}
	// moving to different list
	current.splice(source.index, 1);
	next.splice(destination.index, 0, target);
  
	const result = {
		...treeMap,
		[source.droppableId]: {
			columnID: treeMap[source.droppableId].columnID,
			orderID : treeMap[source.droppableId].orderID,
			title   : treeMap[source.droppableId].title,
			items   : current,
		},
		[destination.droppableId]: {
			columnID: treeMap[destination.droppableId].columnID,
			orderID : treeMap[destination.droppableId].orderID,
			title   : treeMap[destination.droppableId].title,
			items   : next,
		},
	};
  
	return {
		treeMap       : result,
		removed       : target,
		movingSameList: false,
	};
};
  
export function moveBetween({ list1, list2, source, destination }) {
	const newFirst = Array.from(list1.values);
	const newSecond = Array.from(list2.values);
  
	const moveFrom = source.droppableId === list1.id ? newFirst : newSecond;
	const moveTo = moveFrom === newFirst ? newSecond : newFirst;
  
	const [moved] = moveFrom.splice(source.index, 1);
	moveTo.splice(destination.index, 0, moved);
  
	return {
		list1: {
			...list1,
			values: newFirst,
		},
		list2: {
			...list2,
			values: newSecond,
		},
	};
}