import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';

import actions from './actions';
import { casinoAPI } from '../../../helpers/api/casino';
import formatError from '../../../helpers/formaErrorString';
import notifications from '../../../helpers/notifications';
import { adaptCasinoList } from './utils';
import { isPartnerAdmin } from '../../../containers/StaffManagement/modals/Person/utils';
import { logger } from '../../../helpers/logger';

const messages = {
	errorListLoad    : 'casino.error.list.load',
	errorItemDelete  : 'casino.error.item.delete',
	successItemDelete: 'casino.success.item.delete',
};

function getStore(state) {
	const adminType = state.Auth.get('user').adminType;

	return {
		adminType,
	};
}

function* listReload() {

	yield takeEvery(actions.CASINO_LIST_RELOAD, function* ({ data }) {
		yield put(actions.uiRefresh({ loading: true, providerLoading: true }));
		let list = [];

		try {
			const res = yield call(casinoAPI.casinoList, null, data);
			if (res && res.status === 200) {
				list = adaptCasinoList(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad);
			logger.log(error);
		}

		yield put(actions.listRefresh(list));
		yield put(actions.uiRefresh({ loading: false, providerLoading: false }));
	});
}

function* globalListReload() {

	yield takeEvery(actions.CASINO_GLOBAL_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true, providerLoading: true }));
		const list = yield call(makeRequestCasinoList);
		yield put(actions.listRefresh(list));
		yield put(actions.uiRefresh({ loading: false, providerLoading: false }));
	});
}

export function* makeRequestCasinoList(list = []) {
	const { adminType } = yield select(getStore);
	const isPartner = isPartnerAdmin(adminType);

	try {
		let res = null;
		if (isPartner) {
			res = yield call(casinoAPI.partnerCasinoList);
		} else {
			res = yield call(casinoAPI.casinoList);
		}
		if (res && res.status === 200) {
			list = adaptCasinoList(res.data.data);
		}
	} catch (error) {
		notifications.showError(messages.errorListLoad);
		logger.log(error);
	}
	return sortBy(values(list), ['id']);
}

function* deleteItem() {

	yield takeEvery(actions.CASINO_LIST_DELETE_ITEM, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { casinoID } = action.data;
		try {
			const res = yield call(casinoAPI.casinoDelete, casinoID);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successItemDelete);
				yield put(actions.listReload());
			}
		} catch (error) {
			notifications.showError(messages.errorItemDelete);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}


function* reorderPoviderSaga() {
	yield takeEvery(actions.PROVIDERS_REORDER, function* ({ data: { oldIndex, newIndex } }) {
		try {
			const casinoList = yield select(({ Casino }) => Casino.List.get('list'));
			const websiteID = yield select(({ App }) => App.get('websiteID'));
			const clonedList = cloneDeep(casinoList);
			const movedItem = clonedList[oldIndex];
			clonedList.splice(oldIndex, 1);
			clonedList.splice(newIndex, 0, movedItem);
			yield put(actions.listRefresh(clonedList));

			const newUpdatedList = clonedList.map((provider, i) => ({
				provider_id : provider.id,
				type        : provider.type,
				logo_url    : provider.logo,
				name        : provider.name,
				website_id  : websiteID,
				order_number: i + 1,
				alias       : provider.alias,
			}));
			yield call(casinoAPI.casinoUpdateList, newUpdatedList);
		} catch (e) {
			notifications.showError(formatError(e.response.data.text));
		}
	});
}

export default function* casinoListSaga() {
	yield all([
		fork(listReload),
		fork(globalListReload),
		fork(deleteItem),
		fork(reorderPoviderSaga),
	]);
}
