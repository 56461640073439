import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';
import { prepareSettings, prepareLimits, adaptSettingsLimits } from './utils';
import notifications from '../../helpers/notifications';
import actions from './actions';
import { AutoWithdrawalSettingsApi } from '../../helpers/api/autoWithdrawalSettings';
import { isID } from '../../helpers/utils';
import { logger } from '../../helpers/logger';

const messages = {
	errorWebsiteReload                   : 'autowithdrawalSettings.error.website.reload',
	errorWebsiteSave                     : 'autowithdrawalSettings.error.website.save',
	errorAutoWithdrawSettingsReload      : 'autowithdrawalSettings.error.auto.withdraw.settings.reload',
	errorAutoWithdrawSettingsSave        : 'autowithdrawalSettings.error.auto.withdraw.settings.save',
	errorAutoWithdrawSettingsDelete      : 'autowithdrawalSettings.error.auto.withdraw.settings.delete',
	errorAutoWithdrawSettingsReorder     : 'autowithdrawalSettings.error.auto.withdraw.settings.reorder',
	errorAutoWithdrawSettingsLimitsReload: 'autowithdrawalSettings.error.auto.withdraw.settings.limits.reload',
	errorAutoWithdrawSettingsLimitsSave  : 'autowithdrawalSettings.error.auto.withdraw.settings.limits.save',
	errorListLoad                        : 'autowithdrawalSettings.error.auto.withdraw.list.loading',

	successWebsiteSave                   : 'autowithdrawalSettings.success.website.save',
	successAutoWithdrawSettingsSave      : 'autowithdrawalSettings.success.auto.withdraw.settings.save',
	successAutoWithdrawSettingsDelete    : 'autowithdrawalSettings.success.auto.withdraw.settings.delete',
	successAutoWithdrawSettingsReorder   : 'autowithdrawalSettings.success.auto.withdraw.settings.reorder',
	successAutoWithdrawSettingsLimitsSave: 'autowithdrawalSettings.success.auto.withdraw.settings.limits.save',
};


function getStoreData({ AutoWithdrawalSettings, Partner: { Websites }, Settings, App }) {
	return {
		UI            : Websites.Website.get('UI'),
		settingsList  : AutoWithdrawalSettings.get('list'),
		settingslimits: AutoWithdrawalSettings.get('limits'),
		currencyList  : Settings.CurrencyModule.get('list'),
		websiteID     : App.get('websiteID'),
	};
}

function* listReload() {

	yield takeEvery(actions.WEBSITE_AUTOWITHDRAW_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const { websiteID } = action.data;
		let prepareData = {};
		try {
			const res = yield call(AutoWithdrawalSettingsApi.AutoWithdrawSettingsList, websiteID);
			if (res && res.status === 200) {
				const sourse = res.data.data;
				prepareData = prepareSettings(sourse);
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad);
			logger.log(error);
		}
		yield put(actions.listResresh(prepareData.list));
		yield put(actions.limitsResresh(prepareData.limits));
		yield put(actions.uiRefresh({ loading: false }));
	});
}
function* autoWithdrawSettingsSave() {

	yield takeEvery(actions.WEBSITE_AUTOWITHDRAW_SAVE, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const { settingsId, websiteID } = action.data;
		const storeData = yield select(getStoreData);
		const limitData = storeData.settingslimits[settingsId];
		const { settingsList, settingslimits } = storeData;
		const settingsData = storeData.settingsList.filter(item => String(item.id) === String(settingsId));
		try {
			const dataSettings = {
				clean_ip_hours        : settingsData[0].cleanIpHours,
				name                  : settingsData[0].name,
				auto_withdrawal_limits: prepareLimits(limitData),
			};
			let res;
			if (isID(settingsId)) {
				res = yield call(AutoWithdrawalSettingsApi.AutoWithdrawSettingsUpdate, websiteID, settingsId, dataSettings);
			} else {
				res = yield call(AutoWithdrawalSettingsApi.AutoWithdrawSettingsCreate, websiteID, dataSettings);
			}
			const newSettingsList = settingsList.filter(item => item.id !== settingsId);
			const newSettingsLimits = cloneDeep(settingslimits);
			const resData = res.data.data;
			const id  = toInteger(resData.id);
			newSettingsList.push({
				id,
				name        : resData.name,
				websiteId   : toInteger(resData.website_id),
				cleanIpHours: resData.clean_ip_hours,
			});
			delete newSettingsLimits[settingsId];
			newSettingsLimits[id] = adaptSettingsLimits(resData.auto_withdrawal_limits);
			yield put(actions.listResresh(newSettingsList));
			yield put(actions.limitsResresh(newSettingsLimits));
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successWebsiteSave);
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* autoWithdrawSettingsDelete() {

	yield takeEvery(actions.WEBSITE_AUTOWITHDRAW_DELETE, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const settingsId = action.data.id;
		const storeData = yield select(getStoreData);
		const { settingsList, settingslimits } = storeData;
		const newSettingsList = cloneDeep(settingsList).filter(item => item.id !== settingsId);
		const newSettingsLimits = cloneDeep(settingslimits);
		delete newSettingsLimits[settingsId];
		const { websiteID } = storeData;
		try {
			const res = yield call(AutoWithdrawalSettingsApi.AutoWithdrawSettingsDelate, websiteID, settingsId);
			if (res && res.status === 200) {
				yield put(actions.listResresh(newSettingsList));
				yield put(actions.limitsResresh(newSettingsLimits));
				notifications.showSuccess(messages.successWebsiteSave);
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}


export default function* websiteModalSaga() {
	yield all([
		fork(listReload),
		fork(autoWithdrawSettingsSave),
		fork(autoWithdrawSettingsDelete),
	]);
}
