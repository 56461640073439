import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';
import { prepareRisks, prepareSettings } from './utils';
import { showError, showSuccess } from '../../helpers/notifications';
import actions from './actions';
import commonActions from '../common/actions';
import { RiskManagementSettingsApi } from '../../helpers/api/riskManagement';
import { isID } from '../../helpers/utils';
import { logger } from '../../helpers/logger';

const prefix = 'riskManagement';

const messages = {
	errorWebsiteReload                    : `${prefix}.errorWebsiteReload`,
	errorWebsiteSave                      : `${prefix}.errorWebsiteSave`,
	errorRiskManagementSettingsReload     : `${prefix}.errorRiskManagementSettingsReload`,
	errorRiskManagementSettingsSave       : `${prefix}.errorRiskManagementSettingsSave`,
	errorRiskManagementSettingsDelete     : `${prefix}.errorRiskManagementSettingsDelete`,
	errorRiskManagementSettingsReorder    : `${prefix}.errorRiskManagementSettingsReorder`,
	errorRiskManagementSettingsRisksReload: `${prefix}.errorRiskManagementSettingsRisksReload`,
	errorRiskManagementSettingsRisksSave  : `${prefix}.errorRiskManagementSettingsRisksSave`,
	successWebsiteSave                    : `${prefix}.successWebsiteSave`,
	successRiskManagementSettingsSave     : `${prefix}.successRiskManagementSettingsSave`,
	successRiskManagementSettingsDelete   : `${prefix}.successRiskManagementSettingsDelete`,
	successRiskManagementSettingsReorder  : `${prefix}.successRiskManagementSettingsReorder`,
	successRiskManagementSettingsRisksSave: `${prefix}.successRiskManagementSettingsRisksSave`,
};

function getStoreData({ RiskManagementSettings, Partner: { Websites }, Settings }) {
	return {
		UI           : Websites.Website.get('UI'),
		settingsList : RiskManagementSettings.get('list'),
		settingsRisks: RiskManagementSettings.get('risks'),
		currencyList : Settings.CurrencyModule.get('list'),
	};
}

function* listReload() {

	yield takeEvery(actions.WEBSITE_RISK_MANAGEMENT_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		yield put(commonActions.commonRiskGroupListReload());
		const storeData = yield select(getStoreData);
		const {  UI } = storeData;
		const { websiteID } = UI;
		let prepareData = {};
		try {
			const res = yield call(RiskManagementSettingsApi.riskManagementSettingsList, websiteID);
			if (res && res.status === 200) {
				prepareData = prepareSettings(res.data.data);
			}
		} catch (error) {
			showError(messages.errorListLoad);
			logger.log(error);
		}
		yield put(actions.listResresh(prepareData.list));
		yield put(actions.risksResresh(prepareData.risks));
		yield put(actions.uiRefresh({ loading: false }));
	});
}
function* riskManagementSettingsSave() {

	yield takeEvery(actions.WEBSITE_RISK_MANAGEMENT_SAVE, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const settingsId = action.data;
		const storeData = yield select(getStoreData);
		const riskData = storeData.settingsRisks[settingsId];
		const { settingsList, settingsRisks, UI } = storeData;

		const settingsData = storeData.settingsList.filter(item => String(item.id) === String(settingsId));
		const { websiteID } = UI;
		try {
			const dataSettings = {
				name                        : settingsData[0].name,
				risk_group                  : settingsData[0].riskGroup,
				risk_type                   : settingsData[0].riskType,
				priority                    : settingsData[0].priority,
				settings                    : { hours: settingsData[0]?.settings?.hours },
				settings_for_risk_management: prepareRisks(riskData),
			};
			let res;
			if (isID(settingsId)) {
				res = yield call(RiskManagementSettingsApi.riskManagementSettingsUpdate, websiteID, settingsId, dataSettings);
			} else {
				res = yield call(RiskManagementSettingsApi.riskManagementSettingsCreate, websiteID, dataSettings);
			}
			if (res && res.status === 200) {
				const newSettingsList = settingsList.filter(item => String(item.id) !== String(settingsId));
				const resData = res.data.data;
				const intId = toInteger(resData.id);
				const settingsForRiskManagement = resData.settings_for_risk_management.map(item => ({
					currencyID: item.currency_id,
					amount    : item.settings,
				}));
				newSettingsList.push({
					id       : intId,
					name     : resData.name,
					websiteId: toInteger(resData.website_id),
					riskGroup: resData.risk_group,
					riskType : resData.risk_type,
					priority : resData.priority,
					settings : resData.settings,
					settingsForRiskManagement,
				});
				yield put(actions.listResresh(newSettingsList));
				yield put(actions.risksResresh({
					...settingsRisks,
					[intId]: settingsForRiskManagement,
				}));
				showSuccess(messages.successWebsiteSave);
			}
		} catch (error) {
			showError(messages.errorListLoad);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* riskManagementSettingsDelete() {

	yield takeEvery(actions.WEBSITE_RISK_MANAGEMENT_DELETE, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const settingsId = action.data.id;
		const storeData = yield select(getStoreData);
		const { settingsList } = storeData;
		const newSettingsList = cloneDeep(settingsList).filter(item => item.id !== settingsId);

		const { websiteID } = storeData.UI;
		try {
			const res = yield call(RiskManagementSettingsApi.riskManagementSettingsDelate, websiteID, settingsId);
			if (res && res.status === 200) {
				yield put(actions.listResresh(newSettingsList));
				showSuccess(messages.successWebsiteSave);
			}
		} catch (error) {
			showError(messages.errorListLoad);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* websiteModalRiskManagementSaga() {
	yield all([
		fork(listReload),
		fork(riskManagementSettingsSave),
		fork(riskManagementSettingsDelete),
	]);
}
