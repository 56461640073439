export const BANNER_DISPLAY_STATUS = {
	inactive: 1,
	active  : 2,
};

export const BANNER_USER_STATUS = {
	LOGGEDIN                  : 1,
	FIRST_TIME_VISITOR        : 2,
	RETURNING_VISITOR         : 3,
	LOGGEDIN_RETURNING_VISITOR: 4,
	1                         : 'LOGGED IN',
	2                         : 'FIRST TIME VISITOR',
	3                         : 'RETURNING VISITOR',
	4                         : 'LOGGEDIN_RETURNING_VISITOR',
};

export const BANNER_USER_TEXT_STATUS = {
	1: 'Logged In',
	2: 'First Time Visitor',
	3: 'Returning Visitor',
	4: 'Logged In Returning Visitor',
};

export const TRADING_MODE = {
	1       : 'Both',
	2       : 'Prematch',
	3       : 'Inplay',
	both    : 1,
	prematch: 2,
	live    : 3,
};

export const TRADING_MODE_OPTIONS = [
	{ id: TRADING_MODE.both, name: 'Both' },
	{ id: TRADING_MODE.prematch, name: 'PreMatch' },
	{ id: TRADING_MODE.live, name: 'InPlay' },
];

export const DURATIONS = {
	minutesInDay : 1440,
	minutesInHour: 60,
};

export const LIMIT_TYPES = {
	absolute: 1,
	percent : 2,
};

export const ACCEPT_BETS_TIMES = {
	minutes: 1,
	hours  : 2,
	days   : 3,
	weeks  : 4,
	months : 5,
	always : 6,
};

export const CATEGORIES_TYPES = {
	sport      : 1,
	country    : 2,
	league     : 3,
	subleague  : 4,
	event      : 5,
	eventFolder: 6,
	market     : 7,
	selection  : 8,
};

export const DYNAMIC_LIMITS_TIMES = {
	minutes: 1,
	hours  : 2,
	days   : 3,
};

export const FORMATS = {
	// for dayjs
	date          : 'YYYY-MM-DD',
	dateDot       : 'DD.MM.YYYY',
	dateSlash     : 'DD/MM/YYYY',
	fullDateSlash : 'YYYY/MM/DD HH:mm:ss',
	dateHyphen    : 'DD-MM-YYYY',
	dateTime      : 'YYYY-MM-DD HH:mm',
	time          : 'HH:mm:ss',
	timeHM        : 'HH:mm',
	fullDateTime  : 'YYYY-MM-DD HH:mm:ss',
	fullDateMsTime: 'YYYY-MM-DD HH:mm:ss.SSS',
	monthsAndYear : 'MMMM YYYY',

	// for numeral
	integer  : '0',
	decimal  : '0.00',
	sum      : '0,0.00',
	millenial: '0.000',
	finance  : '$ 0,0.00',
	percent  : '0 %',
};

export const TIME_UNITS = {
	years  : 'years',
	months : 'months',
	days   : 'days',
	hours  : 'hours',
	minutes: 'minutes',
	seconds: 'seconds',
};


export const AUTOCOMPLETE_SOURCES = {
	userName     : 'username',
	userEmail    : 'email',
	userFirstName: 'first_name',
	userLastName : 'last_name',
	roleName     : 'role_name',
};

export const CHANNEL_TYPES = {
	web    : 1,
	mobile : 2,
	tablet : 3,
	desktop: 4,
	backend: 5, // removed in platform
};

export const DISPLAY_STATUS = {
	visible      : 1,
	invisible    : 2,
	partlyVisible: 3,
};

export const GENERAL_STATUS = {
	active  : 1,
	inactive: 2,
	archived: 3,
};

export const VERIFICATION_SOUSCE_TYPES = {
	admin : 1,
	system: 2,
};

export const SOURCE_TYPES = {
	feed         : 1,
	admin        : 2,
	casino       : 3,
	paymentSystem: 4,
	system       : 5,
	1            : 'feed',
	2            : 'admin',
	3            : 'casino',
	4            : 'paymentSystem',
	5            : 'system',
};

export const PRICE_PROVIDERS = {
	1: 'Azuro',
};

export const SOCKET_STATUS = {
	connected   : 'Connected',
	disconnected: 'Disconnected',
};

export const YES_NO = {
	yes: 1,
	no : 2,
	1  : { name: 'yes', value: 1 },
	2  : { name: 'no',  value: 2 },
};

export const BET_TYPES = {
	single       : 1,
	express      : 2,
	system       : 3,
	special_offer: 4,
	1            : 'Single',
	2            : 'Multibet',
	3            : 'System',
	4            : 'Special Offer',
};

export const CASINO_BET_TYPES = {
	spin     : 1,
	reSpin   : 2,
	freeSpin : 3,
	bonusSpin: 4,
	tip      : 5,
	promo    : 6,
};

export const PARTNER_FILTER_STATUS = {
	active: 1,
	block : 2,
	delete: 3,
};

export const CASINO_BET_TYPES_NAMES = {
	[CASINO_BET_TYPES.spin]     : 'Spin',
	[CASINO_BET_TYPES.freeSpin] : 'Free Spin',
	[CASINO_BET_TYPES.reSpin]   : 'Re Spin',
	[CASINO_BET_TYPES.bonusSpin]: 'Bonus Spin',
	[CASINO_BET_TYPES.tip]      : 'tip',
};

export const DEPOSIT_BONUS_TYPES = {
	registrationBonus: 1,
	depositBonus     : 2,
	gamingBonus      : 3,
	specialOfferBonus: 4,
	manualAdjustment : 5,
	other            : 6,
};

export const DEPOSIT_TYPES = {
	manual       : 3,
	paymentSystem: 4,
	bonus        : 5,
	cashback     : 6,
	// cashback           : 7,
};

export const DEPOSIT_STATUS = {
	success         : 1,
	pending         : 2,
	failed          : 3,
	inReview        : 4,
	cancelled       : 5,
	cancelledByAdmin: 6,
	refund          : 7,
	refunding       : 8,
	expired         : 9,
	processing      : 10,
	partialRefund   : 11,
	approvedByAdmin : 12,
};

export const WITHDRAWAL_TYPES = {
	manual               : 3,
	paymentSystem        : 4,
	transferFromOtherUser: 5,
	bonus                : 7,
};

export const WITHDRAWAL_STATUS = {
	success         : 1,
	pending         : 2,
	failed          : 3,
	inReview        : 4,
	cancelled       : 5,
	cancelledByAdmin: 6,
	refund          : 7,
	refunding       : 8,
	expired         : 9,
	processing      : 10,
	partialRefund   : 11,
	approvedByAdmin : 12,
};

export const WITHDRAWAL_ELIGIBILITY = {
	yes : 1,
	no  : 2,
	auto: 3,
};

export const WITHDRAWAL_DECISION = {
	approved: 1,
	rejected: 2,
};

export const ACTIVE_STATUS = {
	active  : 1,
	inactive: 0,
};

export const CONTENT_MENU_TYPES = {
	topMenu     : 1,
	footerMenu  : 2,
	articlesMenu: 3,
};

export const INFO_PAGE_TYPES = {
	internal: 1,
	external: 2,
};

export const LINK_ACTION_TYPES = {
	self : 1,
	blank: 2,
};

export const BET_STATUS = {
	open         : 1,
	lost         : 2,
	partialLost  : 3,
	win          : 4,
	partialWin   : 5,
	cancelled    : 6,
	void         : 7,
	inAdminReview: 8,
	inUserReview : 9,
	adminRejected: 10,
	userRejected : 11,
	autoRejected : 12,
	1            : 'Open',
	2            : 'Lost',
	3            : 'PartialLost',
	4            : 'Win',
	5            : 'PartialWin',
	6            : 'Cancelled',
	7            : 'Void',
	8            : 'In Admin Review',
	9            : 'In User Review',
	10           : 'Admin Rejected',
	11           : 'User Rejected',
	12           : 'Auto Rejected',
};

export const LOG_TYPES = {
	category           : 1,
	market             : 2,
	selection          : 3,
	user               : 4,
	bonuses            : 5,
	participant        : 7,
	cashback           : 8,
	welcomeBonus       : 9,
	responsibleGambling: 10,
};

export const CATEGORY_LOG_MODELS = {
	all               : 0,
	category          : 1,
	settings          : 2,
	cms               : 3,
	dynamicLimits     : 4,
	markets           : 5,
	names             : 6,
	orders            : 7,
	leagueRestrictions: 8,
	leagueTournaments : 9,
	sportRegulations  : 10,
	marketsMargin     : 11,
};

export const MARKET_LOG_MODELS = {
	all          : 0,
	market       : 1,
	names        : 2,
	cms          : 3,
	orders       : 4,
	settings     : 5,
	dynamicLimits: 6,
};

export const SELECTION_LOG_MODELS = {
	all          : 0,
	selection    : 1,
	names        : 2,
	orders       : 3,
	settings     : 4,
	dynamicLimits: 5,
};

export const USER_LOG_MODELS = {
	all     : 0,
	user    : 1,
	gambling: 2,
};

export const CASHBACK_LOG_MODELS = {
	main     : 0,
	ggrLimits: 1,
};

export const WELCOME_BONUS_LOG_MODELS = {
	main: '0',
};

export const BET_HISTORY_TYPES = {
	event    : 1,
	market   : 2,
	selection: 3,
};

export const RESULT_TYPES = {
	won        : 1,
	lost       : 2,
	cancelled  : 3,
	void       : 4,
	partialWin : 5,
	partialLost: 6,
};

export const RESULT_SELECTION_LOG_MODELS = {
	eventSelections: 1,
	eventStatistic : 2,
};

export const STATISTIC_SORTS = {
	home: 0,
	away: 1,
};

export const WEBSITE_STATUS = {
	active : 1,
	blocked: 2,
};
export const SUB_CATEGORY_STATUS = {
	provider      : 1,
	customProvider: 2,
	both          : 3,
};


export const WEBSITE_VERIFICATION = {
	none      : 0,
	deposit   : 1,
	withdrawal: 2,
};

export const WEBSITE_MAILING_PROTOCOL = {
	none: 'None',
	ssl : 'SSL',
	tls : 'TLS',
};
export const PROMOTION_STATUS = {
	active  : 1,
	inactive: 2,
	1       : 'active',
	2       : 'inactive',
};

export const WEBSITE_PAYMENTS_STATUS = {
	active : 1,
	blocked: 2,
};

export const LANGUAGES = {
	en: 1,
	ru: 2,
	ua: 3,
	tr: 4,
	de: 5,
};

export const GAME_LIST_LOAD_TYPES = {
	infinitiveScroll: 1,
	loadMore        : 2,
};

export const IMAGE_LINK_TYPES = {
	internal: 1,
	external: 2,
};

export const BANNER_POSITIONS = {
	top   : 1,
	bottom: 2,
	left  : 3,
	right : 4,
	center: 5,
};

export const PRODUCTS = {
	sport : 1,
	casino: 2,
	system: 3,
};

export const EMAIL_ACTIONS = {
	verifyEmail             : 1,
	emailVerified           : 2,
	forgetPasswordRequest   : 3,
	forgetPasswordComplete  : 4,
	changePasswordComplete  : 5,
	newIPLogin              : 6,
	withdrawNotApproved     : 7,
	pendingWithdraw         : 8,
	approvedWithdraw        : 9,
	withdrawCancellation    : 10,
	bonus                   : 11,
	promoCode               : 12,
	verificationKey         : 13,
	welcomeMessage          : 14,
	changeEmail             : 15,
	freeSpin                : 16,
	percentFirstDepositBonus: 17,
	setPartnerPassword      : 19,
	checkEmailTest          : 20,
	suspendPartner          : 21,

};

export const CASINO_API_VERSIONS = {
	one: 1,
	two: 2,
};

export const CASINO_API_HOSTS = {
	europe: 0,
	asia  : 1,
};

export const WEBSITE_CASINO_STATUS = {
	inactive: 0,
	active  : 1,
};

export const CASINO_GAMES_CATEGORY = {
	all: 0,
};

export const CONTENT_GAMES_CATEGORY = {
	all: 0,
};

export const CASINO_GAMES_WEIGHT = {
	halfSmall: 0.5, // Draft Size
	
	tabletSmallest: 0.4,
	tabletSmaller : 0.675,

	tabletMedium      : 0.8,
	tabletMediumMiddle: 1.35,

	tabletBig   : 1.6,
	tabletBigest: 2.7,

	small : 1,
	medium: 2,
	big   : 4,
};

export const CASINO_GAMES_GRID_MODE = {
	byDefault: 'byDefault',
	byGames  : 'byGames',
};

export const CASINO_GAMES_VIEW_TABS = {
	grid: 'grid',
	list: 'list',
};

export const TRANSACTION_TYPES = {
	all              : 0,
	bet              : 1,
	withdraw         : 2,
	manual           : 3,
	deposit          : 4,
	userToUser       : 5,
	bonus            : 6,
	betReverse       : 7,
	wonReverse       : 8,
	won              : 9,
	balanceAdjustment: 10,
	cashback         : 11,
};

export const TRANSACTION_STATUSES = {
	success         : 1,
	pending         : 2,
	failed          : 3,
	inReview        : 4,
	cancelled       : 5,
	cancelledByAdmin: 6,
	refund          : 7,
	refunding       : 8,
	expired         : 9,
	processing      : 10,
	partialRefund   : 11,
	approvedByAdmin : 12,
};

export const PAYWAY_STATUS = {
	visible: 1,
	hidden : 2,
};

export const EMAIL_CATEGORY = {
	inbox    : 1,
	draft    : 2,
	spam     : 3,
	sent     : 4,
	trash    : 5,
	important: 6,
};

export const EMAIL_STATUS = {
	inProcess: 1,
	success  : 2,
	error    : 3,
};

export const SMS_STATUS = {
	success: 1,
	error  : 2,
	pending: 3,
};

export const USER_DOCUMENT_TYPES = {
	internationalPassport: 1,
	nationalPassport     : 2,
	drivingLicense       : 3,
	bill                 : 4,
	other                : 5,
	selfie               : 6,
};

export const USER_DOCUMENT_REVIEW_STATUS = {
	awaitingReview: 1,
	accepted      : 2,
	declined      : 3,
};

export const WEBSITE_SCRIPT_POSITION = {
	head: 1,
	body: 2,
};

export const WEBSITE_SCRIPT_COOKIE_TYPE = {
	NONE              : 1,
	STRICTLY_NECESSARY: 2,
	PERFORMANCE_COOKIE: 3,
	FUNCTIONAL_COOKIES: 4,
	TARGETING_COOKIES : 5,
};

export const MONEY_TYPES = {
	real   : 1,
	virtual: 2,
};

export const USER_INFO_VIEW_TABS = {
	personal             : 'personal',
	transactions         : 'transactions',
	bets                 : 'bets',
	deposits             : 'deposits',
	withdrawals          : 'withdrawals',
	casino               : 'casino',
	balanceAdjustments   : 'balanceAdjustments',
	documents            : 'documents',
	logins               : 'logins',
	actions              : 'actions',
	statistic            : 'statistic',
	roles                : 'roles',
	group                : 'group',
	bonus                : 'bonus',
	sportStatistic       : 'sportStatistic',
	casinoStatistic      : 'casinoStatistic',
	limitsAndRestrictions: 'limitsAndRestrictions',
	responsibleGambling  : 'responsibleGambling',
	notes                : 'notes',
	sendSMS              : 'sendSMS',
	riskManagement       : 'riskManagement',
	partnerWebsite       : 'partnerWebsite',
};

export const CASINO_GAMES_STATUS = {
	active  : 1,
	inactive: 2,
};

export const TRANSACTION_SOURCES = {
	1: 'Interkassa',
	2: 'PayMega Visa/Master',
	3: 'Skrill',
	4: 'Paymega Privat24',

	5: 'Skrill Credit Cards',
	6: 'Skrill Visa',
	7: 'Skrill MasterCard',
	8: 'Skrill American Express',
	9: 'Skrill Neteller',

	10: 'WinPay',
	11: 'Card Pay',
	12: 'Neteller',

	13: 'Winpay Visa or Mastercard RU',
	14: 'Winpay Visa or Mastercard CRD or P2P',
	15: 'Winpay Replenishment of World Card',
	16: 'Winpay Visa or Mastercard World',
	17: 'Winpay Visa or Mastercard UA',
	18: 'Winpay Visa or Mastercard KZ',
	20: 'Winpay Webmoney R',
	21: 'Winpay Yandex Money',
	22: 'Winpay Qiwi Wallet',
	23: 'Winpay Qiwi Visa Wallet',
	24: 'Winpay MTC ru',
	25: 'Winpay Megafon ru',
	26: 'Winpay Beeline ru',
	27: 'Winpay Tele2 ru',
	28: 'Winpay Motive ru',

	60 : 'Accent Pay - Card',
	64 : 'Accent Pay - AFT24',
	78 : 'Accent Pay - KASSA24',
	85 : 'Accent Pay - Qiwi',
	95 : 'Accent Pay - Yandex Money',
	96 : 'Accent Pay - Jeton Voucher',
	196: 'Accent Pay - CashIn',
	197: 'Accent Pay - Promsvyazbank',
	198: 'Accent Pay - Alfa Click',

	199: 'GMBL - WebMoney Z',
	200: 'GMBL - WebMoney R',
	201: 'GMBL - WebMoney E',
	202: 'GMBL - Card',
	203: 'GMBL - QiWi',
	204: 'GMBL - Yandex',
	205: 'GMBL - WebMoney WMR Withdraw',
	206: 'GMBL - MTC',
	207: 'GMBL - Warface',
	208: 'GMBL - WebMoney WMZ Withdraw',
	209: 'GMBL - Card Withdraw',

	210: 'Interkassa - PerfectMoney (USD)',
	211: 'Interkassa - PerfectMoney (EUR)',
	212: 'Interkassa - ADV Cash (RUB)',
	213: 'Interkassa - ADV Cash (USD)',
	214: 'Interkassa - ADV Cash (EUR)',
	215: 'Interkassa - ADV Cash (UAH)',
	216: 'Interkassa - EXMO ADV Cash (RUB)',
	217: 'Interkassa - EXMO ADV Cash (USD)',
	218: 'Interkassa - EXMO ADV Cash (EUR)',
	219: 'Interkassa - EXMO ADV Cash (UAH)',
	220: 'Interkassa - BT Cash (ADV Cash RUB)',
	221: 'Interkassa - BT Cash (ADV Cash USD)',
	222: 'Interkassa - BT Cash (ADV Cash EUR)',
	223: 'Interkassa - Litecoin (ADV Cash RUB)',
	224: 'Interkassa - Litecoin (ADV Cash USD)',
	225: 'Interkassa - Litecoin (ADV Cash EUR)',
	226: 'Interkassa - QIWI (RUB)',
	227: 'Interkassa - QIWI Terminal (RUB)',
	228: 'Interkassa - Svyaznoy (RUB)',
	229: 'Interkassa - AlfaClick (RUB)',
	230: 'Interkassa - YandexMoney (RUB)',
	231: 'Interkassa - MasterCard (UAH)',
	232: 'Interkassa - VISA (UAH)',
	233: 'Interkassa - WorldTerminal (RUB)',
	234: 'Interkassa - MTS (RUB)',
	235: 'Interkassa - Megafon (RUB)',
	236: 'Interkassa - Beeline (RUB)',
	237: 'Interkassa - Tele2 (RUB)',
	238: 'Interkassa - NixMoney (USD)',
	239: 'Interkassa - NixMoney (EUR)',

	240: 'Mercuryo',
	242: 'Paymega2.0',

};

export const CASINO_PROVIDERS = {
	EVOPLAY        : 1,
	SLOTEGRATOR    : 2,
	BETGAMES       : 3,
	BETSOFT        : 4,
	PLAYSON        : 5,
	SPINOMENAL     : 6,
	IGROSOFT       : 7,
	REDRAKE        : 8,
	GAMEART        : 9,
	HABANERO       : 10,
	PLATIPUS       : 11,
	VIVOGAMING     : 12,
	TOMHORN        : 13,
	ENDORPHINA     : 14,
	AMATIC         : 15,
	BOOONGO        : 16,
	MICROGAMING    : 17,
	BIG_TIME_GAMING: 18,
	GAMSHY         : 19,
	MASCOT         : 20,
	PRAGMATIC      : 21,
	DLV            : 22,
	B2BSlots       : 23,
	IGSlots        : 24,
	AlgNet         : 25,
};

export const BET_PROVIDERS = {
	all         : 0,
	ladBrokers  : 1,
	redKings    : 2,
	myBet       : 3,
	sbTech      : 4,
	williamHills: 5,
	betFair     : 6,
	betWay      : 7,
	coral       : 8,
	pinnacle    : 9,
	bet365      : 10,
	betSafe     : 11,
	bWin        : 12,
	sports888   : 13,
	betAtHome   : 14,
	intertops   : 15,
	tonyBet     : 16,
	tipico      : 17,
	tenBet      : 18,
	arcaneBet   : 19,
	tipBet      : 20,
	nordicBet   : 21,
	betsson     : 22,
	betOnline   : 23,
	oneXBet     : 24,
	lsports     : 25,
};

export const DASHBOARD_PERIOD_MODE = {
	arbitrary         : 'arbitrary',
	lastWeek          : 'lastWeek',
	lastMonth         : 'lastMonth',
	lastThreeMonth    : 'lastThreeMonth',
	lastYear          : 'lastYear',
	previousWeek      : 'previousWeek',
	previousMonth     : 'previousMonth',
	previousThreeMonth: 'previousThreeMonth',
	previousYear      : 'previousYear',
};

export const DASHBOARD_TABS = {
	players       : 'players',
	deposits      : 'deposits',
	withdrawals   : 'withdrawals',
	placedBets    : 'placedBets',
	betsByProvider: 'betsByProvider',
};

export const CASINO_BET_STATUS = {
	active    : 1,
	won       : 2,
	lost      : 3,
	betReverse: 4,
};

export const BONUS_TYPES = {
	depositBonus        : 1,
	sportsbookBonus     : 2,
	welcome             : 3,
	firstDeposit        : 4,
	freeSpin            : 5,
	birthday            : 6,
	bonusSpin           : 7,
	registrationFreeSpin: 8,
};

export const RISK_MANAGEMENT_PRIORITY_TYPES = {
	low   : 1,
	medium: 2,
	high  : 3,
};

export const RISK_MANAGEMENT_RISK_TYPES = {
	maxDeposit: 1,
	maxWin    : 2,
};
export const RISK_MANAGEMENT_COMMENT = {
	resolved  : 1,
	unResolved: 2,
};


export const ADMIN_TABLE_TYPES = {
	BET_RTM                       : 1,
	BET_PROFILE                   : 2,
	DEPOSIT_RTM                   : 3,
	DEPOSIT_PROFILE               : 4,
	WITHDRAW_RTM                  : 5,
	WITHDRAW_PROFILE              : 6,
	CASINO_RTM                    : 7,
	CASINO_PROFILE                : 8,
	EVENTS                        : 9,
	RESULTS                       : 10,
	CUSTOMERS_SEARCH              : 11,
	CUSTOMERS_GROUPS              : 12,
	TRANSACTIONS                  : 13,
	INFO_PAGES                    : 14,
	NEWS                          : 15,
	BANNERS                       : 16,
	BONUSES_FREE_SPINS            : 17,
	BONUSES_REGISTRATION_FREE_SPIN: 18,
	BONUSES_WELCOME_DEPOSIT       : 19,
	BONUSES                       : 20,
	PERSONAL                      : 21,
	CONTENT_INFO_PAGES            : 22,
	CONTENT_NEWS                  : 23,
	WEBSITES                      : 24,
	BANNERS_GROUPS                : 25,
	EMAILS_LIST                   : 26,
	EMAIL_TEMPLATES               : 27,
	CASHBACK                      : 29,
	CASHBACK_USERS                : 30,
	HOMEPAGE_SPORTS_PRIORITIES    : 34,
	HOMEPAGE_TOP_LIVE_EVENTS      : 31,
	HOMEPAGE_MATCH_OF_THE_DAY     : 32,
	HOMEPAGE_UPCOMING_EVENTS      : 33,
	BONUSES_FREE_BETS             : 34,
	REPORT_BY_PLAYER              : 35,
	PROMOTIONS                    : 36,
	HELP_CENTER                   : 37,
	REPORT_BY_GAME                : 38,
	REPORT_BY_PROVIDER            : 39,
	REPORT_BY_INTEGRATOR          : 40,
	PARTNERS                      : 41,
	NOTIFICATION                  : 42,
	GAME                          : 43,
	DOCUMENTS_RTM                 : 44,
	RISK_MANAGEMENT_RTM           : 45,
	SMS_LISTING                   : 46,
	TOURNAMENTS                   : 47,
	SPORT_RISK_MANAGEMENT         : 48,
	USER_ALL_TRANSACTION          : 49,
};

export const POINT_GENERATION_RULE_TYPE = {
	allStakes: 'allStakes',
	winStakes: 'winStakes',
};

export const POINT_GENERATION_LOGIC_TYPE = {
	totalSpins: 'totalSpins',
	totalSpent: 'totalSpent',
};
