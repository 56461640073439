import { apiRequest } from './index';

function infoPagesList(params = {}) {

	const req = {
		method: 'GET',
		url   : '/content/informationPage',
		params,
	};

	return apiRequest(req);
}

function infoPageInfo(pageID, params = {}) {
	const id = pageID.replace(/[a-zA-Z]/, '');
	const req = {
		method: 'GET',
		url   : `/content/informationPage/${id}`,
		params,
	};

	return apiRequest(req);
}

function infoPageAdd(pageData, params = {}) {

	const req = {
		method: 'POST',
		url   : '/content/informationPage',
		data  : pageData,
		params,
	};

	return apiRequest(req);
}

function infoPageUpdate(pageID, pageData, params = {}) {

	const req = {
		method: 'POST',
		url   : `/content/informationPage/${pageID}`,
		data  : pageData,
		params,
	};

	return apiRequest(req);
}

function infoPageDelete(pageID, params = {}) {

	const req = {
		method: 'DELETE',
		url   : `/content/informationPage/${pageID}`,
		params,
	};

	return apiRequest(req);
}

function infoPageRevisionsList(pageID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/content/informationPage/${pageID}/revisions`,
		params,
	};

	return apiRequest(req);
}

function infoPageRevisionAdd(pageID, revisionData, params = {}) {

	const req = {
		method: 'POST',
		url   : `/content/informationPage/${pageID}/revisions`,
		data  : revisionData,
		params,
	};

	return apiRequest(req);
}

function infoPageRevisionApply(pageID, revisionID) {

	const req = {
		method: 'POST',
		url   : `/content/informationPage/${pageID}/revisions/${revisionID}`,
	};

	return apiRequest(req);
}

export const infoPagesAPI = {
	infoPagesList,
	infoPageInfo,
	infoPageAdd,
	infoPageUpdate,
	infoPageDelete,

	infoPageRevisionsList,
	infoPageRevisionAdd,
	infoPageRevisionApply,
};
